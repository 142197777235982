import * as ProgressPrimitive from '@radix-ui/react-progress';
import classNames from 'classnames';
import * as React from 'react';

import { cn } from '@/lib/utils';

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & {
    progressIndicatorClassName?: string;
  }
>(
  (
    { className, value, max = 100, progressIndicatorClassName, ...props },
    ref
  ) => (
    <ProgressPrimitive.Root
      ref={ref}
      className={cn(
        'relative h-2 w-full overflow-hidden rounded-full bg-neutral-300',
        className
      )}
      {...props}
    >
      <ProgressPrimitive.Indicator
        className={classNames(
          'h-full w-full flex-1 bg-primary-500 transition-all',
          progressIndicatorClassName
        )}
        style={{
          transform: `translateX(-${100 - (100 / max) * (value || 0)}%)`,
        }}
      />
    </ProgressPrimitive.Root>
  )
);
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
